<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta :content="title" name="description"></Meta>
  </Head>
  <v-layout class="d-flex flex-column">
    <v-app-bar
        v-if="isMobile"
        color="primary"
        height="75">
      <v-container>
        <v-btn
            aria-label="Acessar Tela Inicial"
            class="h-100"
            href="/home"
            variant="text">
          <NuxtImg
              format="webp"
              alt="Logo Sempre Internet"
              height="48"
              placeholder
              src="/logo-sempre-negativa.webp">
          </NuxtImg>
        </v-btn>
      </v-container>
      <v-btn
          aria-label="Abrir menu"
          size="large"
          variant="text"
          @click="menuMobileAtivo = !menuMobileAtivo">
        <v-icon>
          {{ menuMobileAtivo ? 'mdi-close' : 'mdi-menu' }}
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar
        v-else
        color="primary"
        density="default"
        height="200">
      <v-row>
        <v-col
            class="d-flex justify-center align-center"
            cols="12"
            lg="3"
        >
          <v-container class="d-flex justify-center">
            <v-btn
                aria-label="Acessar Tela Inicial"
                class="h-100"
                href="/home"
                variant="text">
              <NuxtImg
                  format="webp"
                  alt="Logo Sempre Internet"
                  height="48"
                  placeholder
                  src="/logo-sempre-negativa.webp"
                  width="200">
              </NuxtImg>
            </v-btn>
          </v-container>
        </v-col>
        <v-col
            class="d-flex justify-center align-center"
            cols="12"
            lg="3"
            sm="4"
        >
    <span class="telefone-contato text-secondary">
      0800 300 0800
    </span>
        </v-col>
        <v-col
            class="d-flex justify-center align-center"
            cols="12"
            lg="3"
            sm="4"
        >
          <v-btn
              class="borda-branca botao-area-assinante"
              href="https://central.sempre.hubsoft.com.br/central/"
              style="color: white !important; text-decoration: none !important;"
              target="_blank">
          <span>
            Área do Assinante
          </span>
          </v-btn>
        </v-col>
        <v-col
            class="d-flex justify-center align-center"
            cols="12"
            lg="3"
            sm="4"
        >
          <LazyMenuNavegacao :menu-itens="opcoesMenuComItens"></LazyMenuNavegacao>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <LazyMenuNavegacaoMobile
          v-if="menuMobileAtivo && isMobile"
          :menu-itens="opcoesMenuComItens"/>
      <v-container fluid style="padding: 0">
        <v-carousel
            class="carrossel"
            cycle
            hide-delimiter-background
            interval="5000"
            show-arrows="hover">
          <v-carousel-item cover>
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE--APP--MOBILE.png' : '/BANNER-APP.png'"
                  alt="Utilize o nosso aplicativo. Veja boletos, faturas e ainda concorra a prêmios"
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item cover>
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-MOBILE.png' : '/BANNER-INDIQUE-&-GANHE.png'"
                  alt="Promoção Indique e Ganhe"
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item cover>
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-RA1000-MOBILE.png' : '/BANNER-RA1000.png'"
                  alt="Somos RA1000 - Reclame Aqui"
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item cover>
            <v-sheet
                height="100%"
                tile>
              <NuxtImg
                  preload
                  :src="isMobile ? '/SITE-FRAUDE-MOBILE.png' : '/BANNER-fraude.png'"
                  alt="
                  A Sempre Internet não divulga campanhas de prêmios através de e-mail,
                   SMS ou qualquer outro canal que não seja oficial da empresa.
                    Os resultados de sorteios e promoções são divulgados através dos canais oficiais da Sempre
                     e o contato com o cliente é realizado via ligação.
                  "
                  class="fill-height w-100"
                  placeholder/>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <v-container
            class="bg-primary text-center"
            fluid>
          <div class="py-10">
            <p class="text-secondary texto-destaque">CLIENTE SEMPRE, É UM PRAZER TÊ-LO<br>CONOSCO.</p>
            <p class="text-white texto-comum">CLIQUE EM UMA DAS OPÇÕES PARA INICIAR UMA AÇÃO. CASO NÃO ENCONTRE<br>
              O QUE DESEJA, ENTRE EM CONTATO CONOSCO ATRAVÉS DE UM DOS CANAIS DISPONÍVEIS.</p>
          </div>
          <div
              v-if="carregandoAcoes"
              class="py-10 actions-container">
            <v-skeleton-loader
                v-for="item in 8"
                class="card-secondary"
                height="180"
                type="image, text"
                width="180"
            ></v-skeleton-loader>
          </div>
          <div
              v-else
              class="py-10 actions-container">
            <LazyCardHome
                v-for="acao of acoes"
                :key="acao.titulo"
                :acao="acao"/>
          </div>
        </v-container>
      </v-container>

    </v-main>
    <v-footer class="bg-primary pb-5">
      <v-row>
        <v-col cols="12" md="4">
          <div class="text-center">
            <span>SIGA-NOS</span>
            <div class="mt-5">
              <a v-for="redeSocial of redesSociais"
                 :aria-label="`${redeSocial.nome} da Sempre Internet`"
                 :href="redeSocial.url"
                 target="_blank">
                <v-icon class="mx-2"
                        size="50">
                  {{ redeSocial.icone }}
                </v-icon>
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="text-center">
            <span>BAIXE NOSSO APP</span>
            <div :class="isMobile ?? 'flex-row'" class="mt-5 d-flex justify-center">
              <v-card
                  class="px-2"
                  href="https://apps.apple.com/br/app/sempre-internet/id1523528026"
                  rounded
                  target="_blank"
                  variant="text">
                <NuxtImg
                    format="webp"
                    loading="lazy"
                    alt="Aplicativo da Sempre Internet na App Store"
                    height="50"
                    placeholder
                    src="/app-store.webp"
                    width="170">
                </NuxtImg>
              </v-card>
              <v-card
                  aria-label="Aplicativo da Sempre Internet no Google Play"
                  class="px-2"
                  href="https://play.google.com/store/apps/details?id=com.hubsoft_client_app.sempre"
                  rounded
                  variant="text">
                <NuxtImg
                    format="webp"
                    loading="lazy"
                    alt="Aplicativo da Sempre Internet na Google Play"
                    height="50"
                    placeholder
                    src="/google-play.webp">
                </NuxtImg>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="text-center">
            <span>FAÇA PARTE DA NOSSA EQUIPE</span>
            <div class="mt-5">
              <v-btn
                  color="secondary"
                  href="https://trabalhenasempre.solides.jobs/"
                  size="large"
                  style="color: white !important; text-decoration: none !important;"
                  variant="elevated">
                Envie seu currículo
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-footer>
    <LazyWhatsappSempre/>
  </v-layout>
</template>
<script lang="ts">
import HeaderSempre from "~/components/header-sempre.vue";
import type {Acao} from "~/pages/home.vue";

export interface OpcaoMenu {
  titulo: string;
  itens?: NavItem[];
}

export interface NavItem {
  titulo: string;
  url: string;
}

export interface RedeSocial {
  icone: string;
  url: string;
  nome: string;
}

export default defineComponent({
  components: {HeaderSempre},
  data() {
    return {
      title: 'Área do Cliente - Sempre Internet',
      menuMobileAtivo: false,
      carregandoAcoes: true,
      navItemsSugestoes: [
        {
          titulo: 'Receber oferta de upgrade',
          url: 'https://api.whatsapp.com/send?phone=558003000800&text=Olá! Acessei o site e gostaria de fazer um UPGRADE no meu plano!'
        },
        {
          titulo: 'Trabalhe conosco',
          url: 'https://trabalhenasempre.solides.jobs/'
        }
      ] as NavItem[],
      navItemsAjuda: [
        {
          titulo: 'Como emitir 2ª via de boleto',
          url: '/2-via-boleto/'
        },
        {
          titulo: 'Nossas Lojas',
          url: '/nossas-lojas'
        },
        {
          titulo: 'Canais de Atendimento',
          url: '/canais-de-atendimento/'
        },
        {
          titulo: 'Política de Privacidade',
          url: '/politica-de-privacidade'
        },
        {
          titulo: 'FAQ Sempre',
          url: '/faq'
        },
        {
          titulo: 'FAQ Ouvidoria',
          url: '/Faq-ouvidoria.pdf'
        }
      ] as NavItem[],
      navItemsSac: [
        {
          titulo: '2ª via de boleto',
          url: 'https://central.sempre.hubsoft.com.br/central/fatura'
        },
        {
          titulo: 'Negociar Débitos',
          url: 'https://central.sempre.hubsoft.com.br/central/fatura'
        },
        {
          titulo: 'Solicitar Desbloqueio',
          url: 'https://central.sempre.hubsoft.com.br/central/servico'
        },
        {
          titulo: 'Teste de Velocidade',
          url: 'https://www.speedtest.net/'
        },
        {
          titulo: 'Regulamentos e condições gerais',
          url: '/regulamentos'
        },
        {
          titulo: 'Ouvidoria',
          url: '/ouvidoria'
        }
      ] as NavItem[],
      opcoesMenu: [
        {titulo: 'SUGESTÕES'},
        {titulo: 'AJUDA'},
        {titulo: 'SAC'}
      ] as OpcaoMenu[],
      redesSociais: [
        {
          icone: 'mdi-facebook',
          url: 'https://www.facebook.com/sempreinternetoficial',
          nome: 'Facebook'
        },
        {
          icone: 'mdi-instagram',
          url: 'https://www.instagram.com/sempreinternet/',
          nome: 'Instagram'
        },
        {
          icone: 'mdi-youtube',
          url: 'https://www.youtube.com/sempreinternet',
          nome: 'Youtube'
        }
      ] as RedeSocial[],
      acoes: [] as Acao[]
    }
  },
  async mounted() {
    try {
      const acoesData = await import('~/assets/data/acoes-home-cliente.json');
      this.acoes = acoesData.default;
    } catch (error) {
      console.error('Erro ao carregar ações:', error);
    } finally {
      this.carregandoAcoes = false;
    }
  },
  computed: {
    opcoesMenuComItens() {
      return this.opcoesMenu.map(opcao => {
        switch (opcao.titulo) {
          case 'SUGESTÕES':
            return {...opcao, itens: this.navItemsSugestoes};
          case 'AJUDA':
            return {...opcao, itens: this.navItemsAjuda};
          case 'SAC':
            return {...opcao, itens: this.navItemsSac};
          default:
            return opcao;
        }
      });
    },
    isMobile() {
      return this.$vuetify.display.xs;
    },
  },
})
</script>
<style scoped>
.actions-container {
  justify-items: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 5px;
}


.carrossel {
  height: auto !important;
}

@media (max-width: 1264px) {
  .actions-container {
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }
}

@media (max-width: 960px) {
  .actions-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .actions-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

.botao-area-assinante {
  border-radius: 10px !important;
}

.borda-branca {
  border: 2px solid white !important;
}

.card-acoes {
  border-radius: 10px !important;
  background-color: transparent !important;
}

.card-primary {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-primary-lighten-1)) 0%, rgb(var(--v-theme-primary-darken-3)) 100%);
}

.card-secondary {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-secondary-lighten-1)) 0%, rgb(var(--v-theme-secondary-darken-3)) 100%)
}

.botao-whatsapp {
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.telefone-contato {
  font-size: 36px;
  font-weight: 900;
}

.texto-comum {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.3em;
}

.texto-destaque {
  font-size: 55px;
  font-weight: 900;
}

a:-webkit-any-link {
  color: white !important;
  transition: color linear 0.2s !important;
  text-decoration: none !important;
}

a:-webkit-any-link:hover {
  color: rgb(var(--v-theme-secondary)) !important;
  text-decoration: underline !important;
}

.v-expansion-panel-text__wrapper {
  padding: 8px 15px 16px !important;
}
</style>
